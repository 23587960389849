import Head from 'next/head';
import React from 'react';

import type { IQuestionBox } from './QuestionBox/QuestionBox';

const getText = (text: IQuestionBox['answer']): string => {
  if (typeof text === 'string') {
    return text;
  }

  if (Array.isArray(text)) {
    return text.map(getText).join(' ');
  }

  return text.simple;
};

const removeHtmlTags = (text: string) => text.replace(/<[^>]*>?/gm, '');

const structuredJSON = (data: IQuestionBox[]) => `{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [${data.map(
    ({ question, answer }) => `{
    "@type": "Question",
    "name": "${question}",
    "acceptedAnswer": {"@type": "Answer","text": ${JSON.stringify(removeHtmlTags(getText(answer)))}}
  }`,
  )}]
}`;

interface IProps {
  data: IQuestionBox[];
}

export const FaqStructuredData: React.FC<IProps> = ({ data }) => (
  <Head>
    <script
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: structuredJSON(data),
      }}
      // eslint-disable-next-line react/no-danger
      type="application/ld+json"
    />
  </Head>
);
