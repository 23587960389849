import React from 'react';

import { Faq } from './Faq/Faq';
import type { IQuestionBox } from './Faq/QuestionBox/QuestionBox';
import { Button } from '@components/Button/Button';
import { Text } from '@components/Text/Text';
import { routes, externalRoutes } from '@routes';

import styles from './FaqSection.module.scss';

export interface IProps {
  data: IQuestionBox[];
  openFirstItem?: boolean;
  isCtaVisible?: boolean;
}

export const FaqSection: React.FC<IProps> = ({ data, openFirstItem, isCtaVisible = true }) => (
  <div className={styles.faqWrapper}>
    <div className={styles.faq}>
      <Faq data={data} openFirstItem={openFirstItem} />
    </div>
    {isCtaVisible && (
      <Text size={14} tag="p">
        Haven’t found an answer to your question? Visit our{' '}
        <Button href={externalRoutes.client.knowledgeBase} external noIcon>
          Help center
        </Button>{' '}
        or <Button href={routes.contact}>contact us</Button>.
      </Text>
    )}
  </div>
);
